import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PageHeader from "../components/PageHeader"
import GiftAnimation, { GiftAnimationV2 } from "../components/GiftAnimation"
import { PageComponent } from "../components/PageComponent"
import LoadingComponent from "../components/LoadingComponent"
import GiftDetails, {
  GiftDetailsVersion2,
  ExpiryVersion2,
  TermsAndConditionsVersion2,
} from "../components/GiftDetails"
import RedemptionInstructions, {
  RedemptionInstructionsVersion2,
} from "../components/RedemptionInstructions"
import BalanceCheckInstructionsVersion2 from "../components/BalanceCheckInstructions"
import AddToWalletVersion2 from "../components/WalletComponent"
import ViewGift, { ButtonBlockVersion2 } from "../components/ViewGift"
import { BarcodeDetails } from "../components/GiftCardCertificate"
import GoogleWalletPasses from "../components/GoogleWalletPasses"

function checkURLHash(
  setPageNotFound,
  setGiftDetails,
  setGiftCard,
  setGiftWrapper,
  setGreetingCard,
  setGreetingMessage,
  setAudioTune,
  setFetchedAnimation,
  urlHash
) {
  fetch(`${process.env.GATSBY_BACKEND}/api/v1/received-gift/${urlHash}/`, {
    method: "GET",
    mode: "cors",
  })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        throw Error("Invalid URL")
      }
    })
    .then(result => {
      fetch(
        `${process.env.GATSBY_BACKEND}/api/v1/giftcard-orders/${result.gift_id}/`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then(response => {
          if (response.status === 200) {
            return response.json()
          }
          throw Error("Invalid URL")
        })
        .then(result => {
          const giftDetails = result
          setGiftDetails(giftDetails)
          if (giftDetails) {
            setGiftCard({
              brand_name: giftDetails.brand_name,
              product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.product_image}`,
              redemption_instructions: giftDetails.redemption_instructions,
            })
            if (giftDetails.greeting_message) {
              setGreetingMessage(giftDetails.greeting_message)
            }
            if (giftDetails.experience.status) {
              setGreetingCard(
                giftDetails.experience.greeting_card
                  ? {
                      product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.greeting_card.front_image}`,
                      product_inner_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.greeting_card.inner_image}`,
                    }
                  : null
              )
              if (!giftDetails.greeting_message) {
                setGreetingMessage(
                  (giftDetails.experience.greeting_card &&
                    giftDetails.experience.greeting_card.message) ||
                    ""
                )
              }
              setGiftWrapper(
                giftDetails.experience.gift_wrapper
                  ? {
                      product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.gift_wrapper.product_image}`,
                    }
                  : null
              )
              setAudioTune(giftDetails.experience.audio_tune || null)
              setFetchedAnimation(giftDetails.experience.animation || null)
            }
          } else {
            setPageNotFound(true)
          }
        })
        .catch(error => {
          console.log(error)
          setPageNotFound(true)
        })
    })
    .catch(error => {
      console.log(error)
      setPageNotFound(true)
    })
}

export function ReceivedGiftVersion1({ giftDetails, giftcard }) {
  return (
    <>
      <GiftDetails giftDetails={giftDetails} giftcard={giftcard} />
      <RedemptionInstructions giftcard={giftDetails} />
      <ViewGift giftDetails={giftDetails} />
    </>
  )
}

const ReceivedGiftVersion2Wrapper = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 #2d2d2d20;
  margin-top: 1rem;
  padding: 3rem;
  @media (max-width: 550px) {
    padding: 3rem 1rem;
    box-shadow: none;
  }
`

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #d2d5d975;
  margin: 1rem 0;
`

function ReceivedGiftVersion2({ giftDetails, giftcard, greetingMessage }) {
  return (
    <ReceivedGiftVersion2Wrapper className="received-gift-v2__wrapper">
      <GiftDetailsVersion2
        giftDetails={giftDetails}
        giftcard={giftcard}
        greetingMessage={greetingMessage}
      />
      {giftDetails && giftDetails.show_barcode && (
        <BarcodeDetails giftDetails={giftDetails} />
      )}
      <ButtonBlockVersion2 giftDetails={giftDetails} />
      <TermsAndConditionsVersion2 giftDetails={giftDetails} />
      <HorizontalLine />
      <RedemptionInstructionsVersion2 giftcard={giftDetails} />
      {giftDetails && giftDetails.balance_check_instructions && (
        <>
          <HorizontalLine />
          <BalanceCheckInstructionsVersion2 giftcard={giftDetails} />
        </>
      )}
      {giftDetails.show_add_to_wallet && (
        <AddToWalletVersion2 giftcard={giftDetails} />
      )}
      {giftDetails.show_add_to_wallet &&
        giftDetails.google_wallet_passes_status && (
          <GoogleWalletPasses giftDetails={giftDetails}></GoogleWalletPasses>
        )}
    </ReceivedGiftVersion2Wrapper>
  )
}

export default function HomePage(props) {
  const [pageNotFound, setPageNotFound] = useState(false)
  const [giftDetails, setGiftDetails] = useState(null)
  const [giftcard, setGiftCard] = useState(null)
  const [giftwrapper, setGiftWrapper] = useState(null)
  const [greetingcard, setGreetingCard] = useState(null)
  const [greetingMessage, setGreetingMessage] = useState(null)
  const [audioTune, setAudioTune] = useState(null)
  const [fetchedAnimation, setFetchedAnimation] = useState(null)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = props.location.search.replace("?", "")

      checkURLHash(
        setPageNotFound,
        setGiftDetails,
        setGiftCard,
        setGiftWrapper,
        setGreetingCard,
        setGreetingMessage,
        setAudioTune,
        setFetchedAnimation,
        urlHash
      )
    } else {
      window.location.replace(`${process.env.GATSBY_EGIFTIT_FRONTEND}/404/`)
    }
  }, [])

  return (
    <PageComponent className="received-gift-page">
      {!giftcard ? (
        <>
          {!pageNotFound ? (
            <LoadingComponent />
          ) : (
            window.location.replace(
              `${process.env.GATSBY_EGIFTIT_FRONTEND}/404/`
            )
          )}{" "}
        </>
      ) : (
        <>
          <PageHeader giftDetails={giftDetails} />
          <GiftAnimationV2
            giftDetails={giftDetails}
            giftcard={giftcard}
            giftwrapper={giftwrapper}
            greetingcard={greetingcard}
            greetingMessage={greetingMessage}
            audioTune={audioTune}
            fetchedAnimation={fetchedAnimation}
          />
          <ReceivedGiftVersion2
            giftDetails={giftDetails}
            giftcard={giftcard}
            greetingMessage={greetingMessage}
          />
        </>
      )}
    </PageComponent>
  )
}
